import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout'
import { motion, AnimatePresence } from "framer-motion";
import { useSpring, animated } from 'react-spring';
import Slider from "react-slick";
import { Link } from 'gatsby-plugin-modal-routing'
import 'font-awesome/css/font-awesome.min.css';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import ImageJS from './image';
import Modal from 'react-modal';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

//images
import arcann from '../images/arcann.png'
import bokatan from '../images/bokatan.png'
import boba from '../images/boba.png'
import death from '../images/death.png'
import kylo from '../images/kylo.png'
import malak from '../images/malak.png'
import mando from '../images/mando.png'
import revan from '../images/revan.png'
import sidious from '../images/sidious.png'
import storm from '../images/storm.png'
import vader from '../images/vader.png'

Modal.setAppElement('#___gatsby');

const Art = ({location}) => {

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -30},
  }

  //mobile
  var mouseMove = ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) });
  var sliders = 6;
  var cMode = false;
  var slides = 3;
  if (isMobile) {
    sliders = 1;
    slides = 1;
    cMode = true;
    mouseMove = 0;
  }

  var settings = {
    arrows: cMode,
    dots: cMode,
    fade: cMode,
    infinite: true,
    speed: 500,
    slidesToShow: sliders,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    rows: 1,
    className: "artCardContainer",
  };

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
  const trans4 = (x, y) => `translate3d(${x / 80}px,${y / 25}px,0)`
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  
  //images
  var [visible, setVisibile] = useState(false);
  var [tooltipName, setTooltipName] = useState(String);

  function hideMe() {
    setVisibile(false);
  }

  function tooltipBokatan() { setTooltipName("Bokatan");setVisibile(true); }
  function tooltipDeath() { setTooltipName("Death Trooper");setVisibile(true); }
  function tooltipBoba() { setTooltipName("Boba Fett");setVisibile(true); }
  function tooltipMando() { setTooltipName("The Mandalorian");setVisibile(true); }
  function tooltipKylo() { setTooltipName("Kylo Ren");setVisibile(true); }
  function tooltipTrooper() { setTooltipName("Storm Trooper");setVisibile(true); }
  function tooltipVader() { setTooltipName("Darth Vader");setVisibile(true); }
  function tooltipSidious() { setTooltipName("Darth Sidious");setVisibile(true); }
  function tooltipRevan() { setTooltipName("Revan");setVisibile(true); }
  function tooltipMalak() { setTooltipName("Darth Malak");setVisibile(true); }
  function tooltipArcann() { setTooltipName("Arcann");setVisibile(true); }

  //modals
  const [modalIsOpen,setIsOpen] = useState(false);
  const [image, setImage] = useState(boba);
  const [innerTxt, setInnerTxt] = useState("Bo");
  const [innerTxt2, setInnerTxt2] = useState("b");
  const [innerTxt3, setInnerTxt3] = useState("a F");
  const [innerTxt4, setInnerTxt4] = useState("e");
  const [innerTxt5, setInnerTxt5] = useState("tt.");
  const [bgColour, setBgColor] = useState('#fff');

  function openBoba() {setIsOpen(true); setImage(boba); setBgColor('#BBD0C7'); setInnerTxt("Bo");setInnerTxt2("b");setInnerTxt3("a F");setInnerTxt4("e");setInnerTxt5("tt.");}
  function openMando() {setIsOpen(true); setImage(mando); setBgColor('#FBE9CD'); setInnerTxt("T");setInnerTxt2("h");setInnerTxt3("e Mandal");setInnerTxt4("o");setInnerTxt5("rian.");}
  function openBokatan() {setIsOpen(true); setImage(bokatan); setBgColor('#B9EBFF'); setInnerTxt("Bo");setInnerTxt2("k");setInnerTxt3("atan.");setInnerTxt4("");setInnerTxt5("");}
  function openKylo() {setIsOpen(true); setImage(kylo); setBgColor('#FBC0C4'); setInnerTxt("K");setInnerTxt2("y");setInnerTxt3("lo R");setInnerTxt4("e");setInnerTxt5("n.");}
  function openDeath() {setIsOpen(true); setImage(death); setBgColor('#A3A4A5'); setInnerTxt("Dea");setInnerTxt2("t");setInnerTxt3("h Troo");setInnerTxt4("p");setInnerTxt5("er.");}
  function openStorm() {setIsOpen(true); setImage(storm); setBgColor('#FCFCFC'); setInnerTxt("Sto");setInnerTxt2("r");setInnerTxt3("m Troop");setInnerTxt4("e");setInnerTxt5("r.");}
  function openVader() {setIsOpen(true); setImage(vader); setBgColor('#B2B2B2'); setInnerTxt("D");setInnerTxt2("a");setInnerTxt3("rth Va");setInnerTxt4("d");setInnerTxt5("er.");}
  function openSidious() {setIsOpen(true); setImage(sidious); setBgColor('#D8D2EE'); setInnerTxt("Dar");setInnerTxt2("t");setInnerTxt3("h Sidio");setInnerTxt4("u");setInnerTxt5("s.");}
  function openRevan() {setIsOpen(true); setImage(revan); setBgColor('#B2B2B2'); setInnerTxt("Rev");setInnerTxt2("a");setInnerTxt3("n.");setInnerTxt4("");setInnerTxt5("");}
  function openMalak() {setIsOpen(true); setImage(malak); setBgColor('#D5B3B1'); setInnerTxt("Dar");setInnerTxt2("t");setInnerTxt3("h Mal");setInnerTxt4("a");setInnerTxt5("k.");}
  function openArcann() {setIsOpen(true); setImage(arcann); setBgColor('#ECFAFF'); setInnerTxt("A");setInnerTxt2("r");setInnerTxt3("cann.");setInnerTxt4("");setInnerTxt5("");}

  function closeModal(){
    setIsOpen(false);
  }

  const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };
  

  return (
    <Layout>
       <style>
        {'main,html { background-color: #C4FFE7; color: #1E283C; }'}
        {'.line { background-color: #59F0A2; }'}
        {'a { color: #1E283C; }'}
        {'a:hover { color: #C4FFE7; }'}
        {'a, a:link { background-image: linear-gradient( #C4FFE7 50%, #1E283C 50%); }'}
        {'.cursor {background-color: #1E283C}'}
        {'.cursor--link-hovered {background-color: #C4FFE7}'}
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </style>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal"
          overlayClassName="modalOverlay"
          preventScroll={true}
          style={{overlay: {
              backgroundColor: (bgColour)
          }}}>  
          <motion.div className='image-container-single'>
            <motion.div
              initial={{
                opacity: 0, 
                scale: 0.3,
              }}
              animate={{ 
                opacity: 1,
                scale: 1,
                transition: { delay: 0, ...transition },
              }}
            className='thumbnail-single'>
            <motion.div
              className='frame-single'
              whileHover='hover'
              transition={transition}>
                <motion.img
                  src={image}
                  alt='an image'
                  className="innerImg"/>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div 
            initial="hidden"
            animate="visible"
            transition={{
              delay: 0.2, 
              type: "spring", stiffness: 100,
              default: { duration: 1 },
              damping: 20,}}
            variants={variants}
            className="imgInfo">
            <h3>{innerTxt}<b>{innerTxt2}</b>{innerTxt3}<b>{innerTxt4}</b>{innerTxt5}</h3>
            <h4>minimal poster</h4>
          </motion.div>
          <motion.button
            initial={{
              scale: 0.3,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            transition={{
              delay: 0.4, 
              type: "spring", stiffness: 100,
              default: { duration: 1 },
              damping: 20,}}
            variants={variants}>
              <button id="closeBtn" onClick={closeModal}>
                <i class="fas fa-times-circle"></i>
              </button>
          </motion.button>
        </Modal>
      
       <div class="background" onMouseMove={mouseMove}>
        <motion.div
              animate={{ rotate: [-10, 0, 10, 0, -10] }}
              transition={{ duration: 20, repeat: Infinity }}>
              <animated.div class="line" id="line1" style={{ transform: props.xy.interpolate(trans1) }}/>
          </motion.div>
          <motion.div
              animate={{ rotate: [5, 15, 25, 15, 5] }}
              transition={{ duration: 50, repeat: Infinity }}>
              <animated.div class="line" id="line2" style={{ transform: props.xy.interpolate(trans2) }}/>
          </motion.div>
          <motion.div
              animate={{ rotate: [-50, -40, -30, -40, -50] }}
              transition={{ duration: 80, repeat: Infinity }}>
              <animated.div class="line" id="line3" style={{ transform: props.xy.interpolate(trans3) }} />
          </motion.div>
       </div>
       
       <div class='content' onMouseMove={mouseMove}>
            <animated.div class="typewriter" style={{ transform: props.xy.interpolate(trans4) }}>
              <h1>ar<b>t.</b></h1>
            </animated.div>

            <animated.div style={{ transform: props.xy.interpolate(trans4) }}>
              <motion.div
                initial="hidden"
                animate="visible"
                transition={{
                delay: 1, 
                type: "spring", stiffness: 100,
                default: { duration: 1 },
                damping: 20,}}
                variants={variants}>
                
                <h5 style={{marginLeft: '10vw', width: '80vw'}}>
                  <span>"</span>I am a <b>Software Developer</b> with a passion for coding applications
                  that are designed to be easy to use. My graphical
                  background helps me create projects that prioritise the user experience.<span>"</span>
                </h5>
              </motion.div>
            </animated.div>

            <br/><br/>
            
            <animated.div style={{ transform: props.xy.interpolate(trans4) }}>
              <motion.div
                initial="hidden"
                animate="visible"
                transition={{
                  delay: 1.5, 
                  type: "spring", stiffness: 100,
                  default: { duration: 1 },
                  damping: 20,}}
                variants={variants}>
                <h3>S<b>t</b>ar Wa<b>r</b>s Minim<b>a</b>l</h3>
                  
              <div class="acContainer">
                <Slider {...settings}>
                    <div className="artCard">
                      <img src={boba} alt="boba" onClick={openBoba}  onMouseOver={tooltipBoba} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={mando} alt="mando" onClick={openMando} onMouseOver={tooltipMando} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={bokatan} alt="bokatan" onClick={openBokatan} onMouseOver={tooltipBokatan} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={death} alt="death" onClick={openDeath} onMouseOver={tooltipDeath} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={kylo} alt="kylo" onClick={openKylo} onMouseOver={tooltipKylo} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={storm} alt="storm" onClick={openStorm} onMouseOver={tooltipTrooper} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={vader} alt="vader" onClick={openVader} onMouseOver={tooltipVader} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={sidious} alt="sidious" onClick={openSidious} onMouseOver={tooltipSidious} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={arcann} alt="arcann" onClick={openArcann} onMouseOver={tooltipArcann} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={revan} alt="revan" onClick={openRevan} onMouseOver={tooltipRevan} onMouseLeave={hideMe}/>
                    </div>
                    <div className="artCard">
                      <img src={malak} alt="malak" onClick={openMalak} onMouseOver={tooltipMalak} onMouseLeave={hideMe}/>
                    </div>
                </Slider>
              </div>
              
              
              <div style={{height: '300px', width: '200px'}}/>

              </motion.div>
            </animated.div>
          
            <MouseTooltip
              visible={visible}
              offsetX={50}
              offsetY={-40}
              className="followMouse">
              {tooltipName}
            </MouseTooltip>

       </div>

       

    </Layout>
  )

}

export default Art;