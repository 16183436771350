import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from 'gatsby-plugin-modal-routing'
import '../components/layout.css';
import 'font-awesome/css/font-awesome.min.css';
import '../components/single.css';

//images
import boba from '../images/boba.png';
import mando from '../images/mando.png';

//Ease
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const Image = ({ location, id }) => {

  var image = boba;

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -30},
  }

  if (id == "mando") {
    image = mando;
  }

  return (
    <motion.div
      className='single'
      initial='initial'
      animate='animate'
      exit='exit'>
      <div className='fluid'>
        <motion.div className='image-container-single'>
          <motion.div
            initial={{
              opacity: 0, 
              scale: 0.3,
          }}
          animate={{ 
            opacity: 1,
            scale: 1,
            transition: { delay: 0.2, ...transition },
          }}
          className='thumbnail-single'>
            <motion.div
              className='frame-single'
              whileHover='hover'
              transition={transition}>
                <motion.img
                  src={image}
                  alt='an image'
                  className="innerImg"
                />
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div 
            initial="hidden"
            animate="visible"
            transition={{
              delay: 1, 
              type: "spring", stiffness: 100,
              default: { duration: 1 },
              damping: 20,}}
            variants={variants}
            className="imgInfo">
            <h3>Bo<b>b</b>a F<b>e</b>tt.</h3>
            <h4>minimal poster</h4>
          </motion.div>
      </div>
      <motion.button
        initial={{
          scale: 0.3,
          opacity: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
        }}
        transition={{
          delay: 1, 
          type: "spring", stiffness: 100,
          default: { duration: 1 },
          damping: 20,}}
        variants={variants}>
          <Link to='/art'><button id="closeBtn">
            <i class="fas fa-times-circle"></i>
          </button></Link>
      </motion.button>
    </motion.div>
  );
};

export default Image;